import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperationsRoutingModule} from './operations-routing.module';
import {OperationsComponent} from './operations/operations.component';
import {NewOperationsComponent} from './new-operations/new-operations.component';
import {ClarityModule, ClrCheckboxModule, ClrDatagridModule} from '@clr/angular';
import {ComponentsModule} from '../../shared/components/components.module';
import {OperationComponent} from './operation/operation.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {ServicesModule} from '../../shared/services/services.module';
import {CdsModule} from '@cds/angular';
import {PermissionsModule} from '../../shared/permissions/permissions.module';
import {
  OperationStateFilterComponent
} from './operations/operations-filters/operation-states-filter/operation-states-filter.component';
import {
  OperationScopesFilterComponent
} from './operations/operations-filters/operation-scopes-filter/operation-scopes-filter.component';
import {OperationApprovalsComponent} from './operations-approvals/operation-approvals.component';
import {
  PendingApprovalsDatagridComponent
} from './operations-approvals/pending-approvals-datagrid/pending-approvals-datagrid.component';
import {
  ClosedApprovalsDatagridComponent
} from './operations-approvals/closed-approvals-datagrid/closed-approvals-datagrid.component';
import {CesiumComponentsModule} from '@ax/ax-angular-map-cesium';
import {CesiumModule} from '../../shared/cesium/cesium.module';
import {NgxMatDatetimePickerModule} from "../../shared/components/datetime-picker/datepicker-module";
import {NgxMatTimepickerModule} from "../../shared/components/datetime-picker/timepicker.module";
import {NgxMatNativeDateModule} from "../../shared/components/datetime-picker/core/native-date.module";
import {InfoboxContainerComponent} from '@ax-uss-ui/common';


@NgModule({
  declarations: [OperationsComponent, NewOperationsComponent, OperationComponent,
    OperationStateFilterComponent, OperationScopesFilterComponent, OperationApprovalsComponent, PendingApprovalsDatagridComponent, ClosedApprovalsDatagridComponent],
    imports: [
        CommonModule,
        OperationsRoutingModule,
        ClrDatagridModule,
        ClarityModule,
        CdsModule,
        ComponentsModule,
        ClrCheckboxModule,
        FormsModule,
        MatDatepickerModule,
        MatInputModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        ReactiveFormsModule,
        MatIconModule,
        ServicesModule,
        PermissionsModule,
        CesiumComponentsModule,
        CesiumModule,
        InfoboxContainerComponent
    ]
})
export class OperationsModule {
}
