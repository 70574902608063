import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ClrDatagrid, ClrDatagridColumn, ClrDatagridSortOrder, ClrDatagridStateInterface} from '@clr/angular';
import {
  BehaviorSubject,
  combineLatest,
  concat,
  EMPTY,
  forkJoin,
  interval,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  timer
} from 'rxjs';
import {Operation} from '../../../shared/model/utm/Operation';
import {FormControl, FormGroup} from '@angular/forms';
import {
  IOperationSearchParameters,
  OperationScope,
  OperationSearchParameters,
  OperationSearchSortField,
  OperationSearchSortFieldUtil,
  OperationService
} from '../../../shared/services/operation.service';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {OperationExt} from '../../../shared/model/utm/OperationExt';
import {SearchResult} from '../../../shared/model/SearchResult';
import {CurrentUserService} from '../../../shared/services/current-user.service';
import {ErrorService} from '../../../shared/services/error.service';
import {state, TelemetryStatusEnum} from '../../../shared/model/gen/utm';
import {DateTime} from 'luxon';
import {OperationUtil} from '../../../shared/model/OperationUtil';
import {catchError, debounceTime, exhaustMap, filter, map, skip, switchMap, take} from 'rxjs/operators';
import {Parser} from '../../../shared/model/utm/parser/OperationParser';
import {IOperation} from '../../../shared/model/gen/transport/utm';
import {ManagedSearchComponent} from '../../../shared/utils/ManagedSearchComponent';
import {User} from '../../../shared/model/User';
import {FormControlify} from '../../../shared/utils/forms';
import * as _ from 'lodash';
import {isInteger, isNil} from 'lodash';
import {CreateOperationMode} from '../new-operations/new-operations.component';
import {DirectMessageTargetIdService, TargetEnum} from '../../../shared/services/direct-message-target-id.service';
import {SettingsService} from '../../../shared/services/settings.service';
import {TelemetryStatusService} from "../../../shared/services/telemetry-status.service";
import {ITelemetryStatus} from "../../../shared/model/telemetry-status";
import {ResponsiveScreenService} from '../../../shared/services/responsive-screen.service';
import {DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL} from '@ax-uss-ui/common';
import {toSignal} from '@angular/core/rxjs-interop';
import StateEnum = IOperation.StateEnum;

interface CustomFiltersFG {
  states: state[];
  scope: OperationScope;
}

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent extends ManagedSearchComponent<IOperationSearchParameters> implements OnInit,
  AfterViewInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  @ViewChildren(ClrDatagridColumn) columns: QueryList<ClrDatagridColumn>;
  @ViewChildren(ClrDatagrid) dg: QueryList<ClrDatagrid>;
  @ViewChild(ClrDatagrid, {read: ElementRef}) dgElement: ElementRef;
  operationToClose: OperationExt;
  waitingForOperationToClose: boolean;
  operationCloseSubmitted: boolean;
  totalItems: number;
  currentPageSize = 10;
  currentPage = 1;
  pageSizeOptions = [5,10,20,50,100];
  loading = true;
  operations: OperationExt[];
  operationState = state;
  isAdmin: Observable<boolean>;
  errorMessage: string;
  showErrorMessage: boolean;
  operationToDelete: OperationExt;
  DATETIME_SHORT = DateTime.DATETIME_SHORT;
  searchExpanded = false;
  filterFG: FormGroup<FormControlify<CustomFiltersFG>>;
  sortDirections: { [key: string]: ClrDatagridSortOrder } = {};
  OP_MODE = CreateOperationMode;
  currentUser: User = null;
  operationTelemetryStatuses: { [key: string]: ITelemetryStatus } = {};
  humanizedLaancSubmissionCategories = DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL.humanized;

  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;
  ussSettings$ = toSignal(inject(SettingsService).getRawSettings());
  enableDirectMessagingSupport$ = computed<boolean>(() => !!this.ussSettings$()?.experimentalSettings.enableDirectMessagingSupport);

  private state: ClrDatagridStateInterface;
  private opsRefreshSub: Subscription;
  private telemetryRefreshSub: Subscription;
  private subscriptions: Subscription[] = [];
  private stateSubject: ReplaySubject<ClrDatagridStateInterface> = new ReplaySubject<ClrDatagridStateInterface>(1);
  private operationsSubject: BehaviorSubject<Operation[]> = new BehaviorSubject<Operation[]>([]);
  private refreshReady = false;

  constructor(private operationService: OperationService,
              private cuService: CurrentUserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private errorService: ErrorService,
              private directMessageTargetIdService: DirectMessageTargetIdService,
              private telemetryStatusService: TelemetryStatusService,
              private cdrf: ChangeDetectorRef,
  ) {
    super(OperationSearchParameters.defaultSearch(), router, activatedRoute);
    this.isAdmin = cuService.isAdmin();
    this.subscriptions.push(this.errorService.errors().subscribe(err => {
      this.showErrorMessage = true;
      this.errorMessage = err;
    }));

    this.filterFG = new FormGroup<FormControlify<CustomFiltersFG>>({
      states: new FormControl<state[]>([state.PROPOSED, state.ACCEPTED, state.ACTIVATED, state.NONCONFORMING, state.ROGUE]),
      scope: new FormControl<OperationScope>(OperationScope.global)
    });

    this.subscriptions.push(this.cuService.currentUser.subscribe(user => {
      this.currentUser = user;
    }));
  }

  ngAfterViewInit(): void {
    // Set initial column values & enable refresh
    this.subscriptions.push(
      this.watchSearchConfig()
        .pipe(take(1))
        .subscribe(config => {
          this.applyColumnChanges(config, true);
          this.refreshReady = true;
          this.refresh();
          this.cdrf.detectChanges();
        })
    );

    // Update column values & refresh datagrid
    this.subscriptions.push(this.watchSearchConfig().pipe(skip(1))
      .pipe(switchMap(config => {
        this.loading = true;
        this.applyColumnChanges(config, false);
        config.fetchCount = true;
        return this.refreshOperations(config, true);
      })).subscribe(([results, config]) => {
        this.loading = false;
        this.totalItems = results.total;
        this.operations = results.results;
        this.operationsSubject.next(this.operations);
      }, () => this.loading = false));

    // Set search config/URL parameters
    this.subscriptions.push(combineLatest([this.stateSubject, concat(of(this.filterFG.value), this.filterFG.valueChanges)])
      .pipe(debounceTime(500))
      .subscribe(([statey, filterValues]) => {
        const newOperationParams: IOperationSearchParameters = this.getOperationSearchParameters(statey, filterValues as CustomFiltersFG);
        this.setSearchConfig(newOperationParams, true);
      }));

    // Refresh operations every 5 seconds
    this.opsRefreshSub = interval(5000).pipe(exhaustMap(() => {
      return this.refreshOperations(this.searchConfig).pipe(catchError(() => (EMPTY)));
    })).subscribe(([results, config]) => {
      if (!_.isEqual(this.operations, results.results)) {
        this.operations = results.results;
        this.operationsSubject.next(this.operations);
      }
    });

    // Refresh telemetry status every 10 seconds (the backend updates the status on a 10 second interval) and when the
    // operations list changes
    this.telemetryRefreshSub = combineLatest([this.operationsSubject, timer(100, 10000)])
      .pipe(debounceTime(500))
      .pipe(map(([ops, i]) => {
        // Filter the operations to track telemetry status for and populate a collection of unique aircraft registration
        // IDs to pass to the telemetry status endpoint
        const registrationIds = new Set<string>();

        const filteredOps = ops.filter(op => {
          if (op.managed && op.state !== state.CLOSED && op.state !== state.REJECTED && op.uas_registrations?.length) {
            registrationIds.add(op.uas_registrations[0].registration_id);
            return true;
          } else {
            delete this.operationTelemetryStatuses[op.operationId];
            return false;
          }
        });

        return {filteredOps, registrationIds};
      }), filter(({filteredOps, registrationIds}) => !!filteredOps.length))
      .pipe(switchMap(({filteredOps, registrationIds}) => {
        return forkJoin([this.telemetryStatusService.getTelemetryStatuses(Array.from(registrationIds))
          .pipe(catchError(() => (EMPTY))), of(filteredOps)]);
      })).subscribe(([statusResults, filteredOps]) => {
        // Store the registration IDs and associated telemetry statuses in an object for easy querying
        const statuses = {};
        statusResults.forEach(result => statuses[result.registrationId] = result);

        // Update the telemetryStatuses object with the results
        // For any registration IDs that didn't have a status returned from the endpoint, set the status to INACTIVE
        filteredOps.forEach(op => {
          const regId = op.uas_registrations[0].registration_id;
          this.operationTelemetryStatuses[op.operationId] = statuses[regId] || {
            registrationId: regId,
            registrationName: null,
            lastSeen: null,
            status: TelemetryStatusEnum.INACTIVE
          };
        });
      });

    // Sorting by scope must be disabled due to the inability of the operation search endpoint to properly sort this field.
    // Warning: This is hacky; however, Clarity currently doesn't support disabling datagrid sorting without also
    // disabling filtering, so there isn't a proper means of doing this.
    this.dgElement?.nativeElement.querySelector('.datagrid-header .col-scope button.datagrid-column-title')?.setAttribute('disabled', '');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refresh();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.opsRefreshSub?.unsubscribe();
    this.telemetryRefreshSub?.unsubscribe();
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }

  refresh(statey?: ClrDatagridStateInterface) {
    if (!statey) {
      statey = this.state || {};
    } else {
      this.state = statey;
    }
    if (!this.refreshReady) {
      return;
    }
    this.stateSubject.next(statey);
  }

  modifyOperation(operation: OperationExt, mode: CreateOperationMode) {
    const queryParams: Params = {operationId: operation.operationId, mode};

    this.router.navigate(['fuss', 'operations', 'newop'], {queryParams});
  }

  viewOperation(operation: OperationExt) {
    this.router.navigate(['fuss', 'operations', 'view-operation'], {queryParams: {operationId: operation.operationId}});
  }

  activateOperation(operation: OperationExt) {
    this.subscriptions.push(this.operationService.activateOperation(operation.operationId).subscribe(() => {
      this.refresh();
    }));
  }

  closeOperation(operation: OperationExt) {
    this.operationToClose = operation;
    this.waitingForOperationToClose = false;
  }

  confirmOperationClose() {
    this.waitingForOperationToClose = false;
    this.subscriptions.push(this.operationService.closeOperation(this.operationToClose.operationId).subscribe(() => {
      this.operationToClose = null;
      this.operationCloseSubmitted = true;
      this.refresh();
    }));
  }

  openSendMessageModal(operationId: string) {
    this.directMessageTargetIdService.notifyOfTargetChange({targetType: TargetEnum.OPERATION, id: operationId});
  }

  getOperationState(operation: Operation, refreshInterval?: number): Observable<state> {
    if (operation.state === state.CLOSED) {
      return of(state.CLOSED);
    }
    return merge(of(operation.state), this.operationService.getOperationState(operation.operationId, refreshInterval));
  }

  getOperationScope(operation: OperationExt): string {
    if (operation.managed) {
      if (operation.additional_data.user_id === this.currentUser?.uid) {
        return 'Owned';
      } else if (operation.additional_data.organization === this.currentUser?.org) {
        return 'My Organization';
      } else {
        return 'My USS';
      }
    } else {
      return 'External';
    }
  }

  getOperationStatePrettyName(operation: Operation, refreshInterval?: number): Observable<string> {
    return this.getOperationState(operation, refreshInterval).pipe(map(OperationUtil.getPrettyOperationStateName));
  }

  truncateWithEllip(str: string, maxLength: number = 100, useWordBoundary: boolean = true): string {
    if (!str) {
      return '';
    }
    if (str.length <= maxLength) {
      return str;
    }
    const subString = str.substring(0, maxLength);
    const isWordBoundary = str[maxLength] === ' ';
    if (useWordBoundary && !isWordBoundary) {
      return subString.substring(0, subString.lastIndexOf(' ') >= 0 ? subString.lastIndexOf(' ') : subString.length) + '...';
    } else {
      return subString + '...';
    }
  }

  formatDateTime(dateTime: DateTime): string {
    return dateTime.toLocal().toFormat('LL/dd/yyyy HH:mm');
  }

  getSearchConfigFromParamMap(params: ParamMap): IOperationSearchParameters {
    let paramMap = params;
    if (!paramMap) {
      paramMap = this.activatedRoute.snapshot.queryParamMap;
    }
    const ret = OperationSearchParameters.defaultSearch();

    if (paramMap.has('categories')) {
      ret.categories = paramMap.getAll('categories');
    }
    if (paramMap.has('state')) {
      const parsedState = (paramMap.getAll('state') || [])
        .map(s => Parser.parseState(s as StateEnum))
        .filter(s2 => !!s2);
      if (parsedState.length) {
        ret.state = parsedState;
      }
    }
    if (paramMap.has('scopes')) {
      const scopes = Parser.parseScope(paramMap.get('scopes'));
      if (scopes) {
        ret.scopes = scopes;
      }
    }
    if (paramMap.has('registrationId')) {
      ret.registrationId = paramMap.getAll('registrationId');
    }
    if (paramMap.has('submitTimeAfter')) {
      ret.submitTimeAfter = DateTime.fromISO(paramMap.get('submitTimeAfter'));
    }
    if (paramMap.has('submitTimeBefore')) {
      ret.submitTimeBefore = DateTime.fromISO(paramMap.get('submitTimeBefore'));
    }
    if (paramMap.has('flightNumber')) {
      ret.flight_number = paramMap.get('flightNumber');
    }
    if (paramMap.has('flightComments')) {
      ret.flight_comments = paramMap.get('flightComments');
    }
    if (paramMap.has('ussName')) {
      ret.uss_name = paramMap.get('ussName');
    }
    if (paramMap.has('managed')) {
      // Only set the managed field if it is true and the scope is set to global. These conditions indicate that the
      // datagrid's scope should be set to the current USS, which needs to be determined programmatically due to it not
      // being a valid server-side value.
      try {
        const managed = JSON.parse(paramMap.get('managed'));
        if (typeof managed !== 'boolean') {
          throw new Error();
        }
        ret.managed = managed === true && ret.scopes === OperationScope.global ? true : undefined;
      } catch {
        console.error('Invalid managed parameter value: ' + paramMap.get('managed'));
      }
    }
    if (paramMap.has('sort')) {
      const sort = (paramMap.getAll('sort') || [])
        .map(OperationSearchSortFieldUtil.fromString)
        .filter(f => f !== OperationSearchSortField.unknown);
      if (sort.length) {
        ret.sort = sort;
      }
    }
    if (paramMap.has('sortIncreasing')) {
      try {
        const sortIncreasing = JSON.parse(paramMap.get('sortIncreasing'));
        if (typeof sortIncreasing !== 'boolean') {
          throw new Error();
        }
        ret.sortIncreasing = sortIncreasing;
      } catch {
        console.error('Invalid value for sortIncreasing URL parameter: ' + paramMap.get('sortIncreasing'));
      }
    }
    if (paramMap.has('limit')) {
      const limit = Number(paramMap.get('limit'));
      if (!isInteger(limit) || !this.pageSizeOptions.includes(limit)) {
        console.error(`Invalid value for limit URL parameter: ${paramMap.get('limit')} \n Valid options are ${this.pageSizeOptions.join(', ')}`);
      } else {
        ret.limit = limit;
      }
    }
    if (paramMap.has('offset')) {
      const offset = Number(paramMap.get('offset'));
      if (!isInteger(offset) || offset < 0) {
        console.error('Invalid value for offset URL parameter: ' + paramMap.get('offset'));
      } else if((offset % ret.limit) !== 0) {
        console.error('offset URL parameter must be divisible by limit parameter');
      } else {
        ret.offset = offset;
      }
    }

    return ret;
  }

  serializeSearchConfigToParams(searchConfig: IOperationSearchParameters): Params {
    return {
      categories: searchConfig.categories,
      state: searchConfig.state,
      scopes: searchConfig.scopes,
      registrationId: searchConfig.registrationId,
      submitTimeAfter: searchConfig.submitTimeAfter?.toISO(),
      submitTimeBefore: searchConfig.submitTimeBefore?.toISO(),
      flightNumber: searchConfig.flight_number,
      flightComments: searchConfig.flight_comments,
      ussName: searchConfig.uss_name,
      managed: JSON.stringify(searchConfig.managed),
      // distance: searchConfig.distance,
      // referencePoint: searchConfig.referencePoint ? JSON.stringify(searchConfig.referencePoint) : undefined,
      sort: searchConfig.sort,
      sortIncreasing: JSON.stringify(searchConfig.sortIncreasing),
      limit: searchConfig.limit,
      offset: searchConfig.offset
    };
  }

  resetFilters() {
    this.applyColumnChanges(OperationSearchParameters.defaultSearch(), true);
  }

  // private initOperationSubs() {
  //   this.clearOperationSubs();
  //   this.operationSubs = [];
  //   for (const op of this.operations) {
  //     this.operationSubs.push(this.operationService.watchOperation(op.operationId).subscribe((newOp) => {
  //       op.setValues(newOp);
  //     }));
  //   }
  // }

  private refreshOperations(searchConfig: IOperationSearchParameters, fetchCount: boolean = true):
    Observable<[SearchResult<OperationExt>, IOperationSearchParameters]> {
    return this.operationService.getOperations(searchConfig).pipe(map(results => [results, searchConfig]));
  }

  private getOperationSearchParameters(statey: ClrDatagridStateInterface, filters: CustomFiltersFG): IOperationSearchParameters {
    // Parse filters from array of objects -> single object with key/value pairs
    const dgFilters: { [prop: string]: string } = {};
    statey.filters?.forEach(filter => dgFilters[this.extractFieldNameFromDotNotation(filter.property)] = filter.value);

    // Parse sort field
    let sort: OperationSearchSortField[];
    if (statey.sort?.by) {
      sort = [OperationSearchSortFieldUtil.fromString(this.extractFieldNameFromDotNotation(statey.sort.by.toString()))];
      if (sort[0] === OperationSearchSortField.unknown) {
        sort = undefined;
      }
    }

    // Parse scope field
    let newScope: OperationScope = filters.scope;
    // Since a scopes value for current USS doesn't exist server-side, we set it to global and the managed field to true
    if (filters.scope === OperationScope.currentUSS) {
      newScope = OperationScope.global;
    }

    return new OperationSearchParameters({
      categories: [],
      state: filters.states,
      scopes: newScope,
      flight_number: dgFilters?.flight_number,
      flight_comments: dgFilters?.flight_comments,
      uss_name: dgFilters?.uss_name,
      managed: filters.scope === OperationScope.currentUSS ? true : undefined,
      sort,
      sortIncreasing: !isNil(statey.sort?.reverse) ? !statey.sort?.reverse : undefined,
      limit: statey.page?.size,
      offset: statey.page?.from === -1 ? 0 : statey.page?.from
    });
  }

  private applyColumnChanges(config: IOperationSearchParameters, emitChanges: boolean) {
    if (this.columns) {
      // Set column filter values
      this.columns.forEach(col => {
        const columnFieldName = this.extractFieldNameFromDotNotation(col.field);
        if (columnFieldName in config) {
          col.filterValue = config[columnFieldName];
          col.filterValueChange.emit(config[columnFieldName]);
        }
      });

      // Set column sort directions
      if (config.sort?.length) {
        const columnSortFields = this.columns.map(col => col.field).filter(col => !!col);
        columnSortFields.push('effective_time_begin', 'effective_time_end');

        columnSortFields.forEach(field => {
          if (config.sort[0] === this.extractFieldNameFromDotNotation(field)) {
            this.sortDirections[field] = config.sortIncreasing ? ClrDatagridSortOrder.ASC : ClrDatagridSortOrder.DESC;
          } else {
            this.sortDirections[field] = ClrDatagridSortOrder.UNSORTED;
          }
        });
      }
    }

    if (config.state?.length) {
      this.filterFG.controls.states.setValue(config.state, {emitEvent: false, onlySelf: true});
    }

    // Since a scopes value for current USS doesn't exist server-side, it is dependent on the following conditions:
    //  1. The managed field is set to true
    //  2. The scope is set to global
    if (config.managed && config.scopes === OperationScope.global) {
      this.filterFG.controls.scope.setValue(OperationScope.currentUSS, {emitEvent: false, onlySelf: true});
    } else if (config.scopes) {
      this.filterFG.controls.scope.setValue(config.scopes, {emitEvent: false, onlySelf: true});
    }

    this.currentPageSize = config.limit;
    this.currentPage = Math.floor((config.offset / config.limit)) + 1;

    if (this.dg && emitChanges) {
      this.dg.notifyOnChanges();
      this.filterFG.updateValueAndValidity();
    }
  }

  private extractFieldNameFromDotNotation(field: string): string {
    return field?.includes('.') ? field.split('.').pop() : field;
  }
}
